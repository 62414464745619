import React from 'react';
import config from "../config.json";
import Carousel from 'nuka-carousel';

const Slider = () => {
    return (
        <div className='d-flex position-relative slider'>
            <Carousel autoplay={true} autoplayReverse={true} width="100%" height="800px" wrapAround={true} autoplayInterval={3000}>
                <img src={config.ImageUrl + "/uploads/products/slide/slide1.webp"} />
                <img src={config.ImageUrl + "/uploads/products/slide/slide3.webp"} />
                <img src={config.ImageUrl + "/uploads/products/slide/slide4.webp"} />
                <img src={config.ImageUrl + "/uploads/products/slide/slide5.webp"} />
                <img src={config.ImageUrl + "/uploads/products/slide/slide4.webp"} />
                <img src={config.ImageUrl + "/uploads/products/slide/slide7.webp"} />
            </Carousel>
            <div className="position-absolute ml-1 w-100 text-white text-dlider text-center animate__animated animate__bounce animate__fadeInLeft" style={{ bottom: "5px" }}>
                <h4>
                    Willkommen bei KM1 ( Küche & Möbel Montage 1 )
                </h4>
                <h6>
                    Ihrem Experten für professionelle Küchen- und Möbelmontage!
                </h6>
            </div>
        </div>
    );
}

export default Slider;
