import React from "react";
import Joi from "joi-browser";
import Form from "./common/form";
import auth from '../services/authService';

class RegisterForm extends Form {
  state = {
    data: { email: "", password: "" },
    errors: ""
  };

  schema = {
    email: Joi.string()
      .required()
      .email()
      .label("Email"),
    password: Joi.string()
      .required()
      .min(6)
      .label("Passwort")
  };

  componentDidMount() {
    window.scrollTo(0, 0)
  }

  doSubmit = async () => {
    try {
      const { data } = this.state;
      await auth.login(data.email, data.password)
      window.location = "/";
    } catch (ex) {
      if (ex.response && ex.response.status === 401) {
        const errors = "error";
        this.setState({ errors });
      }
    }
  };

  render() {
    return (
      <div className='form-position row pb-5 ml-2 mr-2'>
        <div className='col-xl-4 col-lg-4 col-md-2 col-sm-0'></div>
        <div className='col-xl-4 col-lg-4 col-md-8 col-sm-12 border p-4'>
          <div className="pb-5 text-dark">
            <h3>Deine Anmeldedaten</h3>
          </div>
          <form onSubmit={this.handleSubmit}>
            {this.renderInput("email", "Deine E-Mail-Adresse:", "email")}
            {this.renderInput("password", "Dein Passwort:", "password")}

            <div className="pb-2 text-danger">
              {this.state.errors === "error" ? "Achtung: Die E-Mail-Adresse oder das Passwort stimmen nicht !" : ""}
            </div>

            {this.renderButton("Anmelden")}
          </form>
        </div>

        <div className='col-xl-4 col-lg-4 col-md-2 col-sm-0'></div>
      </div>
    );
  }
}

export default RegisterForm;