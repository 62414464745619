import React from "react";
import { FcInfo } from "react-icons/fc";

const Textarea = ({ name, label, error, ...rest }) => {
    return (
        <div className="form-group">
            <FcInfo title="das muss ausgefüllt werden" /><label className="ml-2" htmlFor={name}>{label}</label>
            <textarea {...rest} name={name} id={name} className="form-control" />
            {error && <p className="text-danger">{error}</p>}
        </div>
    );
};

export default Textarea;