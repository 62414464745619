import React, { Component } from 'react';
import Slider from './slider';
import Main from './main';

class Home extends React.Component {

    componentDidMount() {
        window.scrollTo(0, 0)
    }

    render() {
        return (
            <div>
                <div>
                    <Slider />
                </div>
                <div>
                    <Main />
                </div>
            </div>
        );
    }
}

export default Home;