import React from 'react';
import config from "../config.json";

class Main extends React.Component {
    render() {
        return (
            <React.Fragment>
                <div className="row pt-5">
                    <div className="col-xl-6 col-lg-6 col-md-12 col-sm-12">
                        <div className="text-muted pl-1">
                            <h4 className="text-dark font-weight mb-4">
                                Exzellente Montagedienstleistungen für Küchen und Möbel, die Ihre Erwartungen übertreffen
                            </h4>
                            <p >
                                Wir freuen uns, Sie auf unserer Webseite begrüßen zu dürfen.Bei KM1 sind wir stolz darauf, hochwertige Montagedienstleistungen für Küchen und Möbel anzubieten, die Ihre Vorstellungen übertreffen werden.<br /> Unser engagiertes Team von Fachleuten bringt nicht nur umfangreiche Erfahrung mit, sondern auch eine Leidenschaft für präzise Arbeit und exzellenten Kundenservice.<br />
                                Was uns wirklich auszeichnet, ist unser engagiertes Team von Fachleuten. Unsere Experten bringen nicht nur umfangreiche Erfahrung in der Möbelmontage mit, sondern auch eine tief verwurzelte Hingabe zur präzisen Arbeit und zum unübertroffenen Kundenservice. Wir verstehen, dass jede Küche und jedes Möbelstück einzigartig ist, und wir setzen all unsere Fachkenntnisse ein, um Ihre Visionen in die Realität umzusetzen.
                            </p>
                        </div>
                        <div className="row pl-1">
                            <div className="col-xl-3 col-lg-3 col-md-2 col-sm-1">
                                <img src={config.ImageUrl + "/uploads/products/main/main1-1.webp"} className="img-thumbnail" alt="VPBAUGROUP" style={{ height: "170px", width: "100%" }} />
                            </div>
                            <div className="col-xl-3 col-lg-3 col-md-2 col-sm-1">
                                <img src={config.ImageUrl + "/uploads/products/main/main1-2.webp"} className="img-thumbnail" alt="KM1" style={{ height: "170px", width: "100%" }} />
                            </div>
                            <div className="col-xl-3 col-lg-3 col-md-2 col-sm-1">
                                <img src={config.ImageUrl + "/uploads/products/main/main1-3.webp"} className="img-thumbnail" alt="KM1" style={{ height: "170px", width: "100%" }} />
                            </div>
                            <div className="col-xl-3 col-lg-3 col-md-2 col-sm-1">
                                <img src={config.ImageUrl + "/uploads/products/main/main1-4.webp"} className="img-thumbnail" alt="KM1" style={{ height: "170px", width: "100%" }} />
                            </div>
                        </div>
                    </div>
                    <div className="shrink col-xl-6 col-lg-6 col-md-12 col-sm-12">
                        <img src={config.ImageUrl + "/uploads/products/main/main1.webp"} className="img-thumbnail" alt="KM1" />
                    </div>
                </div>
                {/* ********************************************END FIRST*****************************************************/}
                <div className="row mt-5">
                    <div className="shrink col-xl-6 col-lg-6 col-md-12 col-sm-12">
                        <img src={config.ImageUrl + "/uploads/products/main/main2.webp"} className="img-thumbnail" alt="KM1" />
                    </div>
                    <div className="col-xl-6 col-lg-6 col-md-12 col-sm-12">
                        <div className="text-muted pr-2">
                            <h4 className="text-dark mb-4">
                                Was uns auszeichnet
                            </h4>
                            <div>
                                <p>
                                    <b>Handwerkskunst:</b>
                                    Unsere Montageprofis sind Meister ihres Fachs und verstehen die Feinheiten, die eine perfekte Montage ausmachen. Egal, ob es sich um eine maßgeschneiderte Küche oder um individuelle Möbel handelt, wir setzen auf höchste handwerkliche Qualität.<br /><br />
                                    <b>Maßgeschneiderte Lösungen:</b>
                                    Wir wissen, dass jeder Kunde einzigartige Anforderungen hat. Deshalb bieten wir maßgeschneiderte Lösungen an, die genau auf Ihre Wünsche zugeschnitten sind. Ihre Zufriedenheit steht für uns an erster Stelle.<br /><br />
                                    <b>Zuverlässigkeit:</b>
                                    Pünktlichkeit und zuverlässige Arbeit sind für uns selbstverständlich. Wir verstehen, wie wichtig Ihre Zeit ist, und halten uns an vereinbarte Termine, um die Montage ohne Verzögerungen abzuschließen.<br /><br />
                                    <b>Kundenzufriedenheit</b>
                                    Unser Ziel ist es, nicht nur Möbel und Küchen zu montieren, sondern auch langanhaltende Beziehungen aufzubauen. Wir sind erst zufrieden, wenn Sie es sind.
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
                {/* ********************************************END SECOUND*****************************************************/}
                <div className="row pt-5">
                    <div className="col-xl-6 col-lg-6 col-md-12 col-sm-12">
                        <div className="text-justify text-muted pl-2">
                            <h4 className="text-dark mb-4">
                                Von Vision zu Wirklichkeit: Erwecken Sie Ihre Traumküche und Möbel mit KM1 zum Leben
                            </h4>
                            <p>
                                Warten Sie nicht länger, um den ersten Schritt zu machen. Kontaktieren Sie uns noch heute, damit wir Ihre Ideen ausführlich besprechen können.<br />Unsere erfahrenen Berater stehen Ihnen zur Seite, um Ihre Pläne zu erfassen und Ihnen dabei zu helfen, die besten Entscheidungen für Ihr Projekt zu treffen.
                                Gemeinsam werden wir einen Termin für eine Beratung vereinbaren, bei dem Sie Gelegenheit haben, Ihre Wünsche zu äußern und von unserem Fachwissen zu profitieren.
                            </p>
                        </div>
                        <div className="row pr-1">
                            <div className="col-xl-3 col-lg-3 col-md-2 col-sm-1">
                                <img src={config.ImageUrl + "/uploads/products/main/main3-1.webp"} className="img-thumbnail" alt="KM1" style={{ height: "170px", width: "100%" }} />
                            </div>
                            <div className="col-xl-3 col-lg-3 col-md-2 col-sm-1">
                                <img src={config.ImageUrl + "/uploads/products/main/main3-2.webp"} className="img-thumbnail" alt="KM1" style={{ height: "170px", width: "100%" }} />
                            </div>
                            <div className="col-xl-3 col-lg-3 col-md-2 col-sm-1">
                                <img src={config.ImageUrl + "/uploads/products/main/main3-3.webp"} className="img-thumbnail" alt="KM1" style={{ height: "170px", width: "100%" }} />
                            </div>
                            <div className="col-xl-3 col-lg-3 col-md-2 col-sm-1">
                                <img src={config.ImageUrl + "/uploads/products/main/main3-4.webp"} className="img-thumbnail" alt="KM1" style={{ height: "170px", width: "100%" }} />
                            </div>
                        </div>
                    </div>
                    <div className="shrink col-xl-6 col-lg-6 col-md-12 col-sm-12">
                        <img src={config.ImageUrl + "/uploads/products/main/main3.webp"} className="img-thumbnail" alt="KM1" />
                    </div>
                </div>
            </React.Fragment>
        );
    }
}

export default Main;