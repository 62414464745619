import React from 'react';
import ModalImage from "react-modal-image";
import config from "../../config.json";

const ProjectRow = ({ itemRow, user, onDelete }) => {
    return (
        <div className='border border-dark mt-5'>
            <div className="row mt-3 p-5" key={itemRow.id}>
                <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 mt-3 shrink">
                    <div className="mb-2">
                        <p className="text-dark d-inline">
                            <b>{itemRow.name}</b>
                        </p>
                        <p className="text-dark mt-5">{itemRow.description}</p>
                    </div>
                    {user && (<button className="btn btn-sm btn-danger mt-1" onClick={() => onDelete(itemRow.id)}>Löschen</button>)}
                </div>
            </div>

            <div className='row m-4'>
                <div className="col-xl-3 col-lg-3 col-md-12 col-sm-12 mt-3 img-thumbnail shrink">
                    <ModalImage
                        small={config.ImageUrl + "/uploads/products/" + itemRow.image1}
                        large={config.ImageUrl + "/uploads/products/" + itemRow.image1}
                        showRotate={true}
                        alt={itemRow.name}
                        className={"image-product"}
                    />
                </div>
                <div className="col-xl-3 col-lg-3 col-md-12 col-sm-12 mt-3 img-thumbnail shrink">
                    <ModalImage
                        small={config.ImageUrl + "/uploads/products/" + itemRow.image2}
                        large={config.ImageUrl + "/uploads/products/" + itemRow.image2}
                        showRotate={true}
                        alt={itemRow.name}
                        className={"image-product"}
                    />
                </div>
                <div className="col-xl-3 col-lg-3 col-md-12 col-sm-12 mt-3 img-thumbnail shrink">
                    <ModalImage
                        small={config.ImageUrl + "/uploads/products/" + itemRow.image3}
                        large={config.ImageUrl + "/uploads/products/" + itemRow.image3}
                        showRotate={true}
                        alt={itemRow.name}
                        className={"image-product"}
                    />
                </div>
                <div className="col-xl-3 col-lg-3 col-md-12 col-sm-12 mt-3 img-thumbnail shrink">
                    <ModalImage
                        small={config.ImageUrl + "/uploads/products/" + itemRow.image4}
                        large={config.ImageUrl + "/uploads/products/" + itemRow.image4}
                        showRotate={true}
                        alt={itemRow.name}
                        className={"image-product"}
                    />
                </div>
            </div>
        </div>
    );
}

export default ProjectRow;
