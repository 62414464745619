import React from 'react';

const Career = () => {
    return (
        <div style={{ marginTop: "100px", marginLeft: "10px", marginRight: "10px" }}>
            <div id="accordion">
                <div className="card">
                    <div className="card-header">
                        <a className="btn text-dark" data-toggle="collapse" href="#collapseOne">
                            <b>Stellenanzeige: Küchenmonteur (m/w/d)</b>
                        </a>
                    </div>
                    <div id="collapseOne" className="collapse show" data-bs-parent="#accordion">
                        <div className="card.body">
                            Wir suchen einen engagierten und erfahrenen Küchenmonteur (m/w/d), der unser Team bereichert und unsere Kunden mit professioneller Arbeit und hervorragendem Kundenservice beeindruckt.<br /><br />
                            <b>Aufgaben:</b><br />
                            <ul>
                                <li>Montage von Küchen nach Plan und Vorgaben</li>
                                <li>Sorgfältige Installation von Elektrogeräten und Sanitäranlagen</li>
                                <li>Gewährleistung höchster Qualität und Ästhetik bei der Montage</li>
                                <li>Kundenorientierte Beratung und Betreuung vor Ort</li>
                            </ul>
                            <b>Anforderungen:</b><br />
                            <ul>
                                <li>Abgeschlossene Ausbildung im handwerklichen Bereich, vorzugsweise als Schreiner, Tischler oder vergleichbar</li>
                                <li>Erfahrung in der Küchenmontage sowie im Umgang mit Elektro- und Sanitärinstallationen</li>
                                <li>Handwerkliches Geschick und Auge für Details</li>
                                <li>Freundliches Auftreten und ausgeprägte Kommunikationsfähigkeiten</li>
                                <li>Führerschein Klasse B</li>
                            </ul>
                            <b>Wir bieten:</b><br />
                            <ul>
                                <li>Abwechslungsreiche und verantwortungsvolle Tätigkeit</li>
                                <li>Angenehmes Arbeitsumfeld in einem motivierten Team</li>
                                <li>Wettbewerbsfähige Vergütung und Benefits</li>
                                <li>Fortbildungsmöglichkeiten zur persönlichen Weiterentwicklung</li>
                            </ul>
                            <b>Interessiert?</b><br />
                            <p>Wenn Sie eine Leidenschaft für handwerkliche Arbeit haben, kundenorientiert denken und eine spannende berufliche Herausforderung suchen, freuen wir uns auf Ihre Bewerbung. Senden Sie Ihren Lebenslauf und ein Anschreiben an <b>job@montagekm1.de</b>.</p><br />
                            <p>Wir sind gespannt darauf, Sie näher kennenzulernen!</p>
                        </div>
                    </div>
                </div>

                <div className="card">
                    <div className="card-header">
                        <a className="collapsed btn text-dark" data-toggle="collapse" href="#collapseTwo">
                            <b>Stellenanzeige: Möbelmonteur (m/w/d)</b>
                        </a>
                    </div>
                    <div id="collapseTwo" className="collapse" data-bs-parent="#accordion">
                        <div className="card.body">
                            Wir suchen einen engagierten und erfahrenen Möbelmonteur (m/w/d), der unser Team bereichert und unsere Kunden mit professioneller Arbeit und hervorragendem Kundenservice beeindruckt.<br /><br />
                            <b>Aufgaben:</b><br />
                            <ul>
                                <li>Montage von Möbelstücken gemäß Montageanleitungen und Vorgaben</li>
                                <li>Sorgfältige Installation von Schränken, Betten, Tischen und anderen Möbeln</li>
                                <li>Qualitätskontrolle und Sicherstellung der einwandfreien Montage</li>
                                <li>Kundenorientierte Beratung und Betreuung vor Ort</li>
                            </ul>
                            <b>Anforderungen:</b><br />
                            <ul>
                                <li>Handwerkliche Ausbildung im Bereich Schreinerei, Tischlerei oder vergleichbar</li>
                                <li>Erfahrung in der Montage von Möbeln und im Umgang mit Montagewerkzeugen</li>
                                <li>Präzises und sorgfältiges Arbeiten</li>
                                <li>Freundliches Auftreten und ausgeprägte Kommunikationsfähigkeiten</li>
                                <li>Führerschein Klasse B von Vorteil</li>
                            </ul>
                            <b>Wir bieten:</b><br />
                            <ul>
                                <li>Abwechslungsreiche und verantwortungsvolle Tätigkeit im Bereich Möbelmontage</li>
                                <li>Angenehmes Arbeitsumfeld in einem dynamischen Team</li>
                                <li>Wettbewerbsfähige Vergütung und Benefits</li>
                                <li>Möglichkeiten zur persönlichen Weiterentwicklung und Fortbildung</li>
                            </ul>
                            <b>Interessiert?</b><br />
                            <p>Wenn Sie handwerklich begabt sind, ein Auge für Details haben und Freude daran haben, hochwertige Möbelstücke zu montieren, freuen wir uns auf Ihre Bewerbung. Senden Sie uns Ihren Lebenslauf und ein kurzes Anschreiben an <b>job@montagekm1.de</b>.</p><br />
                            <p>Wir sind gespannt darauf, Sie näher kennenzulernen!</p>
                        </div>
                    </div>
                </div>

                <div className="card">
                    <div className="card-header">
                        <a className="collapsed btn text-dark" data-toggle="collapse" href="#collapseThree">
                            <b>Stellenanzeige: Fahrer (m/w/d)</b>
                        </a>
                    </div>
                    <div id="collapseThree" className="collapse" data-bs-parent="#accordion">
                        <div className="card.body">
                            Wir suchen einen zuverlässigen und erfahrenen Fahrer (m/w/d), der unser Team unterstützt und für eine reibungslose und pünktliche Lieferung unserer Produkte sorgt.<br /><br />
                            <b>Aufgaben:</b><br />
                            <ul>
                                <li>Auslieferung von Waren an unsere Kunden gemäß Tourenplan</li>
                                <li>Sicherstellung der korrekten Beladung und Sicherung der Fracht</li>
                                <li>Einhaltung von Verkehrsregeln und Vorschriften für einen sicheren Transport</li>
                                <li>Pflege und Wartung des Fahrzeugs</li>
                            </ul>
                            <b>Anforderungen:</b><br />
                            <ul>
                                <li>Gültiger Führerschein Klasse B (oder höher, je nach Fahrzeugtyp)</li>
                                <li>Erfahrung im Führen von Lieferfahrzeugen oder vergleichbaren Fahrzeugen</li>
                                <li>Gute Ortskenntnisse und Fähigkeit zur Orientierung</li>
                                <li>Verantwortungsbewusstsein und zuverlässige Arbeitsweise</li>
                                <li>Freundliches Auftreten und gute Kommunikationsfähigkeiten</li>
                            </ul>
                            <b>Wir bieten:</b><br />
                            <ul>
                                <li>Abwechslungsreiche Tätigkeit mit eigenverantwortlichem Arbeiten</li>
                                <li>Angenehmes Arbeitsumfeld in einem teamorientierten Unternehmen</li>
                                <li>Attraktive Vergütung und Zusatzleistungen</li>
                                <li>Gelegenheit zur persönlichen Weiterentwicklung und Fortbildung</li>
                            </ul>
                            <b>Interessiert?</b><br />
                            <p>Wenn Sie Freude am Fahren haben, zuverlässig sind und gerne in einem dynamischen Umfeld arbeiten möchten, freuen wir uns auf Ihre Bewerbung. Bitte senden Sie uns Ihren Lebenslauf und ein kurzes Anschreiben an <b>job@montagekm1.de</b>.</p><br />
                            <p>Wir sind gespannt darauf, Sie näher kennenzulernen!</p>
                        </div>
                    </div>
                </div>

                <div className="card">
                    <div className="card-header">
                        <a className="collapsed btn text-dark" data-toggle="collapse" href="#collapseFour">
                            <b>Stellenanzeige: Planer (m/w/d)</b>
                        </a>
                    </div>
                    <div id="collapseFour" className="collapse" data-bs-parent="#accordion">
                        <div className="card.body">
                            Wir suchen einen engagierten und kreativen Planer (m/w/d), der unsere Projekte maßgeblich vorantreibt und innovative Lösungen entwickelt.<br /><br />
                            <b>Aufgaben:</b><br />
                            <ul>
                                <li>Entwicklung von maßgeschneiderten Planungskonzepten für unsere Kunden</li>
                                <li>Erstellung von detaillierten Plänen, Skizzen und Visualisierungen</li>
                                <li>Zusammenarbeit mit internen Teams, Kunden und externen Partnern</li>
                                <li>Sicherstellung der Einhaltung von Zeitplänen und Budgetvorgaben</li>
                            </ul>
                            <b>Anforderungen:</b><br />
                            <ul>
                                <li>Abgeschlossenes Studium oder Ausbildung im Bereich Architektur, Bauwesen oder vergleichbar</li>
                                <li>Erfahrung in der Planung und Gestaltung von Bauprojekten</li>
                                <li>Sicherer Umgang mit Planungssoftware und CAD-Programmen</li>
                                <li>Kreativität und Lösungsorientierung</li>
                                <li>Kommunikationsstärke und Teamfähigkeit</li>
                            </ul>
                            <b>Wir bieten:</b><br />
                            <ul>
                                <li>Spannende Projekte und die Möglichkeit zur Mitgestaltung</li>
                                <li>Innovatives Arbeitsumfeld mit modernen Arbeitsmitteln</li>
                                <li>Attraktive Vergütung und Benefits</li>
                                <li>Fortbildungsmöglichkeiten zur persönlichen Weiterentwicklung</li>
                            </ul>
                            <b>Interessiert?</b><br />
                            <p>Wenn Sie Leidenschaft für Planung haben, gerne in einem inspirierenden Umfeld arbeiten und neue Ideen vorantreiben möchten, freuen wir uns auf Ihre Bewerbung. Bitte senden Sie uns Ihren Lebenslauf und ein Anschreiben an <b>job@montagekm1.de</b>.</p><br />
                            <p>Wir sind gespannt darauf, Sie näher kennenzulernen!</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Career;