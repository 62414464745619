import React, { Component } from 'react';
import config from "../config.json";

class About extends React.Component {

    componentDidMount() {
        window.scrollTo(0, 0)
    }

    render() {
        return (
            <div>
                <div className='row mt-5 ml-1'>
                    <div className='col-xl-6 col-lg-6 col-md-12 col-sm-12 text-dark pt-5 pl-1'>
                        <p className='text-dark'><b>Mehran Abbasi Moghaddam(Geschäftsführer)</b></p>
                        <p className='text-dark'>
                            ich freue mich, Sie als Geschäftsführer unseres Unternehmens im Bereich Hausdekoration begrüßen zu dürfen.<br />
                            Mit einem Hintergrund in Architektur und einer Leidenschaft für kreatives Gestalten habe ich über 14 Jahre lang in der Welt der
                            Inneneinrichtung gearbeitet.<br />
                            In dieser Zeit durfte ich zahlreiche Projekte im Bereich Küche, Schlafzimmer, Wohnzimmer und mehr betreuen. Jedes Projekt ist für mich eine einzigartige Gelegenheit, die Persönlichkeit und die Bedürfnisse unserer Kunden in stilvolle
                            Designs umzuwandeln.<br />
                            Unser Team und ich streben stets nach
                            Exzellenz und sind bestrebt, Ihnen hochwertige und maßgeschneiderte Lösungen anzubieten.<br />
                            Wir verstehen, dass jedes Zuhause eine eigene Geschichte erzählt, und wir sind hier, um diese Geschichte durch ansprechende Designs zum Leben zu erwecken.
                            <br />Vielen Dank, dass Sie sich die Zeit nehmen, unsere Webseite zu besuchen.<br /> Wir freuen uns darauf, gemeinsam mit Ihnen Räume zu schaffen, die sowohl ästhetisch ansprechend als auch funktional sind.
                            Dienstleistungen zu erfahren oder um Ihr nächstes Projekt zu besprechen.
                        </p>
                    </div>
                    <div className='col-xl-6 col-lg-6 col-md-12 col-sm-12 pt-5 pr-2 d-flex justify-content-center'>
                        <img className='image-abaut mb-5 img-thumbnail' src={config.ImageUrl + "/uploads/products/about/mehran.webp"} alt='Geschäftführer' style={{ height: "70%", width: "50%" }} />
                    </div>
                </div>

                <div className='row ml-2'>
                    <div className='col-xl-6 col-lg-6 col-md-12 col-sm-12 text-dark pl-1'>
                        <p className='text-dark'>
                            <b>Delaram Nouri(Planer für Küchen, Möbel und Innendesign)</b>
                        </p>
                        <p className='text-dark'>
                            Stell dir vor, du könntest leere Räume in atemberaubende und funktionale Umgebungen verwandeln, in denen sich Menschen wohlfühlen und inspiriert werden.<br />
                            Als Planer für Küchen, Möbel und Innendesign ist genau das meine Leidenschaft und Aufgabe.<br />In der Welt des Designs geht es nicht nur um das Arrangieren von Möbeln oder die Wahl von Farben. Es ist eine Kunst, die das Zusammenspiel von Ästhetik, Funktionalität und persönlichem Geschmack vereint.<br />
                            Als Planer für Küchen, Möbel und Innendesign führst du all diese Elemente zusammen, um Räume zu gestalten, die nicht nur schön aussehen, sondern auch einen Zweck erfüllen.<br/><br/>
                            <b>Küchenplaner:</b>Als Küchenplaner bin ich der Architekt einer der wichtigsten Räume im Haus. Du überlegst, wie der Raum am besten genutzt werden kann, arrangierst Schränke, Arbeitsflächen und Geräte, um eine nahtlose und effiziente Umgebung zu schaffen.<br />
                            Von der Auswahl der Materialien bis hin zur Berücksichtigung von Ergonomie und modernen Technologien - bin gestalte Küchen, die sowohl praktisch als auch stilvoll sind.<br />
                            <b>Möbelplaner:</b>
                            Möbelplaner sind die Visionäre hinter individuellen Einrichtungsstilen. ich nehmet die Vorlieben und Bedürfnisse der Kunden auf und entwirfst Möbel, die genau zu ihrem Lebensstil passen. Du jonglierst mit Formen, Größen, Materialien und Details, um Stücke zu schaffen, die nicht nur einzigartig sind, sondern auch den Raum harmonisch ergänzen.<br/>
                            <b>Innendesigner:</b>
                            Innendesigner sind die Geschichtenerzähler von Räumen. ich male mit Farben, strukturierst mit Texturen und spielst mit Licht, um Emotionen und Stimmungen zu erzeugen. Von der Auswahl der richtigen Beleuchtung bis zur Platzierung von Accessoires - ich gestalte Räume, die eine Botschaft vermitteln und eine Atmosphäre schaffen.
                        </p>
                    </div>
                    <div className='col-xl-6 col-lg-6 col-md-12 col-sm-12 pr-2 d-flex justify-content-center'>
                        <img className='image-abaut mb-5 img-thumbnail' src={config.ImageUrl + "/uploads/products/about/delaram.webp"} alt='Geschäftführer' style={{ height: "70%", width: "50%" }} />
                    </div>
                </div>

                <div className='row ml-2'>
                    <div className='col-xl-6 col-lg-6 col-md-12 col-sm-12 text-dark pl-1'>
                        <p className='text-dark'>
                            <b>Unsere Team</b>
                        </p>
                        <p className='text-dark'>
                            Seit unserer Gründung haben wir es uns zur Aufgabe gemacht, herausragende Handwerkskunst und unübertroffenen Kundenservice zu bieten. KM1 wurde aus der Leidenschaft für Präzision und Ästhetik geboren, und unser engagiertes Team von Fachleuten setzt diese Leidenschaft in jedem Montageprojekt um.<br />
                            Unsere Geschichte begann mit dem einfachen Ziel, die Montage von Küchen und Möbeln auf ein neues Niveau zu heben. Mit Jahren an Erfahrung und Fachwissen sind wir stolz darauf, Tausenden von Kunden dabei geholfen zu haben, ihre Wohnräume in funktionale und schöne Umgebungen zu verwandeln.<br /><br />
                            <b>Unsere Werte treiben uns an</b><br />
                            <b>Exzellenz:</b> Wir streben nach nichts Geringerem als nach Exzellenz in allem, was wir tun. Jeder Handgriff, jede Schraube und jedes Detail werden sorgfältig und präzise ausgeführt, um die bestmöglichen Ergebnisse zu erzielen.<br />
                            <b>Vertrauen:</b> Vertrauen ist das Fundament unserer Beziehungen sei es zu unseren Kunden, Partnern oder Mitarbeitern. Wir legen Wert auf transparente Kommunikation, Verlässlichkeit und Integrität.<br />
                            <b>Kundenzentrierung:</b> Unsere Kunden stehen im Mittelpunkt unseres Handelns. Wir hören aufmerksam zu, verstehen Ihre Bedürfnisse und arbeiten eng mit Ihnen zusammen, um Ihre Vorstellungen in die Realität umzusetzen.<br />
                            <b>Innovation:</b> Wir bleiben stets auf dem neuesten Stand der Montagetechnologien und -trends, um innovative Lösungen anzubieten, die sowohl funktional als auch ästhetisch beeindruckend sind.
                            Wir glauben fest daran, dass eine erfolgreiche Zusammenarbeit auf Vertrauen und Engagement basiert. Wenn Sie mit KM1 arbeiten, können Sie sich auf eine Partnerschaft verlassen, die Ihr Vertrauen wert ist. Unsere Leidenschaft für Montage und Design treibt uns an, Ihre Visionen zu verwirklichen und Räume zu schaffen, die Sie stolz Ihr Zuhause nennen können.
                        </p>
                    </div>
                    <div className='col-xl-6 col-lg-6 col-md-12 col-sm-12 pr-2 d-flex justify-content-center'>
                        <img className='image-abaut mb-5 img-thumbnail' src={config.ImageUrl + "/uploads/products/about/team.webp"} alt='Geschäftführer' style={{ height: "70%", width: "50%" }} />
                    </div>
                </div>
            </div>
        )
    }
}

export default About;