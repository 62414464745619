//import jwtDecode from "jwt-decode";
import http from "./httpService";
import Config from "../config.json";

const apiEndpoint = Config.apiUrl + "/login";
const tokenKey = "token";
const tokenName = "Name";

export async function login(email, password) {
  const { data: jwt } = await http.post(apiEndpoint, { email, password });
  localStorage.setItem(tokenKey, jwt.token);
  localStorage.setItem(tokenName, jwt.user.name);
}

export function loginWithJwt(jwt, name) {
  localStorage.setItem(tokenKey, jwt);
  localStorage.setItem(tokenName, name);
}

export function logout() {
  localStorage.removeItem(tokenKey);
  localStorage.removeItem(tokenName);
}

export function getCurrentUser() {
  try {
    const token = localStorage.getItem(tokenKey);
    const name = localStorage.getItem(tokenName);
    return name;
  } catch (ex) {
    return null;
  }

}

export function getJwt() {
  return localStorage.getItem(tokenKey);
}

export default {
  login,
  loginWithJwt,
  logout,
  getCurrentUser,
  getJwt
};
