import React from 'react';
import config from "../config.json";

class Privcy extends React.Component {

    componentDidMount() {
        window.scrollTo(0, 0)
    }

    render() {
        return (
            <div className='p-5 m-5 text-dark'>
                <h5 className='font-weight-bold'>DATENSCHUTZ</h5>
                <hr />
                <p>
                    Bei der <b>"MONTAGEKM1"</b> haben der Schutz und die Sicherheit Ihrer personenbezogenen Daten oberste Priorität. Deshalb behandeln wir diese vertraulich und entsprechend der gesetzlichen Regeln der relevanten Datenschutzgesetze, insbesondere der europäischen Datenschutz-Grundverordnung (DSGVO) sowie dieser Datenschutzerklärung.<br />
                    Die vorliegende Datenschutzerklärung erstreckt sich auf die Nutzung der digitalen Angebote von <b>"MONTAGEKM1"</b> einschließlich unserer Social Media Profile über PC, Smartphones, Tablets und alle weiteren internetfähigen mobilen Endgeräte.
                    Die digitalen Angebote können Links zu anderen Internetseiten dritter Dienstanbieter enthalten, auf die sich diese Datenschutzerklärung nicht bezieht.
                </p><br />

                <h5 className='font-weight-bold' >Verantwortlicher </h5>
                <p>Verantwortlicher für die Verarbeitung Ihrer personenbezogenen Daten ist</p><br /><br />

                <h5 className='font-weight-bold'>{config.address.company}</h5>
                <p><b>Vertreten durch die Geschäftsführer:</b><br /><b>Ing Mehran Abbasi Moghaddam</b><br />
                    <b>{config.address.street}</b><br />
                    <b>{config.address.plz}</b> <b>{config.address.city}</b><br />
                    <b>{config.address.plz}</b> <b>{config.contact.email}</b><br />
                    Sofern Sie Fragen zum Datenschutz bei uns haben, schreiben Sie uns bitte unter der vorgenannten Postadresse, mit dem Zusatz „Datenschutz" oder unter der angegebenen E-Mail-Adresse an.
                </p><br />

                <h5 className='font-weight-bold'>Datenverarbeitung zur Erbringung der vertraglichen Leistungen</h5>
                <p>Wir verarbeiten personenbezogene Daten, um die vertraglichen Verhältnisse abzuwickeln sowie, um bedarfsgerechte Vertragsangebote unterbreiten zu können. Die Erhebung der Daten erfolgt dabei insbesondere zum Abschluss bzw. zur Durchführung eines Vertrages.
                    Von unseren Geschäftspartnern erfragen wir insbesondere Namens- und Kontaktdaten von Ansprechpartnern des Unternehmens zum Zwecke der Vorbereitung und Durchführung des Vertragsverhältnisses.
                    Grundlage für die Datenverarbeitung ist Art. 6 Abs. 1 lit. b DSGVO, der die Verarbeitung von Daten zur Erfüllung eines Vertrags oder vorvertraglicher Maßnahmen gestattet.
                </p><br />

                <h5 className='font-weight-bold'>Download von Pressematerialien</h5>
                <p>Sie haben auf unserer Internetseite die Möglichkeit über ein Formular Pressematerialien über unser Unternehmen und unsere Werke herunterzuladen. Hierfür benötigen wir Ihren vollständigen Namen, Ihre E-Mail-Adresse und Informationen darüber für welche Redaktion Sie tätig sind. Diese Angaben sind erforderlich, da eine honorarfreie Veröffentlichung nur im Rahmen eines redaktionellen Beitrags über die Architektur der Architekten von Gerkan, Marg und Partner (gmp) im Kontext des Inhaltes dieser Pressemitteilung gestattet ist. Wir behalten uns vor, Ihre Angaben zur Verfolgung unserer rechtlichen Interessen bei Zuwiderhandlung zu verarbeiten.<br />
                    Wir haben ein berechtigtes Interesse an der rechtmäßigen Verwendung unserer Pressematerialien. Grundlage für die Datenverarbeitung ist sodann Art. 6 Abs. 1 lit. f DSGVO, der die Verarbeitung von Daten zur Wahrung berechtigter Interessen des Verantwortlichen gestattet, sofern die Interessen oder Grundrechte und Grundfreiheiten des Betroffenen nicht überwiegen.
                </p><br />

                <h5 className='font-weight-bold'>Datenverarbeitung zur Wahrung berechtigter Interessen</h5>
                <p>Sie können über unsere Internetseiten und unsere dort hinterlegten Kontaktdaten Bewerbungen für Stellen in unserem Unternehmen an uns richten. Soweit auf diesem Weg oder auf andere Weise bei Bewerbungen personenbezogene Daten von Ihnen an uns übermittelt werden, verarbeiten wir Ihre Daten für die Prüfung, Bearbeitung und Beantwortung Ihrer Bewerbung sowie ggf. zur Vorbereitung des Beschäftigungsverhältnisses.<br />
                    Grundlage für die Datenverarbeitung ist entweder Art. 6 Abs. 2 DSGVO, § 26 Abs. 1 BDSG (neu) der die Verarbeitung von Daten zur Entscheidung über die Begründung, für die Begründung sowie für die Durchführung von Beschäftigungsverhältnissen gestattet oder – sofern Sie eine Einwilligung erteilt haben – Art. 6 Abs. 1 lit. a DSGVO. Sie können eine von Ihnen erteilte Einwilligung jederzeit mit Wirkung für die Zukunft widerrufen. Dazu reicht eine formlose Mitteilung per E-Mail an uns. Die Rechtmäßigkeit der bereits erfolgten Datenverarbeitungsvorgänge bleibt vom Widerruf unberührt.
                </p><br />

                <h5 className='font-weight-bold'>Datenverarbeitung bei Bewerbungen</h5>
                <p>
                    Ihre Daten verarbeiten wir auch, wenn es erforderlich ist, um berechtigte Interessen von uns oder von Dritten zu wahren. Dies kann insbesondere der Fall sein zur Gewährleistung der IT-Sicherheit und des IT-Betriebs, insbesondere auch bei Support-Anfragen, um im Falle rechtlicher Auseinandersetzungen Sachverhalte nachvollziehen und belegen zu können oder um die Nutzung unserer Website statistisch auszuwerten.<br />
                    Grundlage für die Datenverarbeitung ist Art. 6 Abs. 1 lit. f DSGVO. Wir haben ein berechtigtes Interesse an den vorstehend aufgeführten Datenverarbeitungen.
                </p><br />

                <h5 className='font-weight-bold'>Datenverarbeitung auf Grundlage Ihrer Einwilligung</h5>
                <p>
                    Es kann zudem vorkommen, dass wir für eine Verarbeitung von personenbezogenen Daten von Ihnen Ihre Einwilligung erbitten. Jede Erteilung einer Einwilligung und die jeweils relevante Datenverarbeitung erfolgt auf freiwilliger Basis und bei Nichteinwilligung entstehen Ihnen daraus keine Nachteile.<br />
                    Die Datenverarbeitung erfolgt sodann auf Grundlage Ihrer Einwilligung gem. Art. 6 Abs. 1 lit. a DSGVO.<br />
                    Sie können eine von Ihnen erteilte Einwilligung jederzeit mit Wirkung für die Zukunft widerrufen. Dazu reicht eine formlose Mitteilung per E-Mail an uns. Die Rechtmäßigkeit der bereits erfolgten Datenverarbeitungsvorgänge bleibt vom Widerruf unberührt.
                </p><br />

                <h5 className='font-weight-bold'>Log-Dateien</h5>
                <p>
                    Bei jedem Zugriff auf unsere Internetseiten werden Nutzungsdaten durch den jeweiligen Internetbrowser übermittelt und in Protokolldateien, den sogenannten Server-Logfiles, gespeichert. Die dabei gespeicherten Datensätze enthalten die folgenden Daten: Browsertyp und Browserversion, verwendetes Betriebssystem, Referrer URL, Uhrzeit der Serveranfrage, gekürzte IP-Adresse. <br />
                    Diese Daten sind nicht bestimmten Personen zuordenbar. Eine Zusammenführung dieser Daten mit anderen Datenquellen wird nicht vorgenommen. Wir behalten uns vor, diese Daten nachträglich zu prüfen, wenn uns konkrete Anhaltspunkte für eine rechtswidrige Nutzung bekannt werden.<br />
                    Grundlage für die Datenverarbeitung ist Art. 6 Abs. 1 lit. f DSGVO, der die Verarbeitung von Daten zur Wahrung berechtigter Interessen des Verantwortlichen gestattet, sofern die Interessen oder Grundrechte und Grundfreiheiten des Betroffenen nicht überwiegen.
                </p><br />

                <h5 className='font-weight-bold'>Datenverarbeitung zur Erfüllung gesetzlicher Verpflichtungen</h5>
                <p>
                    Darüber hinaus verarbeiten wir Ihre Daten zur Erfüllung gesetzlicher Verpflichtungen (z.B. aufsichtsrechtlicher Vorgaben, handels- und steuerrechtlicher Aufbewahrungs- und Nachweispflichten).<br />
                    Grundlage für die Datenverarbeitung ist Art. 6 Abs. 1 lit. c DSGVO, der die Verarbeitung zur Erfüllung einer rechtlichen Verpflichtung gestattet.
                </p><br />

                <h5 className='font-weight-bold'>Kategorien von Empfängern der personenbezogenen Daten</h5>
                <p>
                    Ihre personenbezogenen Daten werden an Dritte nur weitergegeben oder sonst übermittelt, wenn dies zum Zweck der Vertragsabwicklung oder Abrechnung erforderlich ist oder Sie zuvor eingewilligt haben oder eine gesetzliche Grundlage für die Weitergabe besteht.<br />
                    Sofern es zum Zwecke der Vertragsabwicklung erforderlich ist, erfolgt eine Datenweitergabe an Partnerunternehmen, welche zur Unterstützung der Vertragsabwicklung beauftragt worden sind. Unsere Partner verpflichten sich zur Einhaltung und Beachtung der datenschutzrechtlichen Bestimmungen. Unseren Partnern ist es zudem nicht gestattet, die Daten anderweitig als zur Vertragsabwicklung zu verwenden.<br />
                    Dienstleister, die uns bei der Erbringung unserer Leistung Ihnen gegenüber unterstützen sind Vertriebs- und Marketingpartner, Software (SaaS)-Anbieter, IT-Dienstleister, insbes. Dienstleister für Soft- und Hardware-Wartung, Hosting-Provider und E-Mail-Dienstprovider.
                </p><br />

                <h5 className='font-weight-bold'>Dauer der Datenspeicherung</h5>
                <p>
                    Grundsätzlich löschen wir Ihre Daten, sobald sie für die oben genannten Zwecke nicht mehr erforderlich sind, es sei denn, die vorübergehende Aufbewahrung ist weiterhin notwendig. So speichern wir Ihre Daten aufgrund gesetzlicher Nachweis- und Aufbewahrungspflichten, die sich unter anderem aus dem Handelsgesetzbuch und der Abgabenordnung ergeben. Die Speicherfristen betragen danach bis zu zehn vollen Jahren. Zudem bewahren wir Ihre Daten für die Zeit auf, in der Ansprüche gegen unser Unternehmen geltend gemacht werden können (gesetzliche Verjährungsfrist von drei oder bis zu dreißig Jahren).
                </p><br />

                <h5 className='font-weight-bold'>Datensicherheit</h5>
                <p>
                    Ihre persönlichen Daten werden bei uns sicher durch Verschlüsselung übertragen. Wir bedienen uns dabei des Codierungssystems SSL (Secure Socket Layer). Eine verschlüsselte Verbindung erkennen Sie daran, dass die Adresszeile des Browsers von „http://“ auf „https://“ wechselt und an dem Schloss-Symbol in Ihrer Browserzeile. Des Weiteren sichern wir unsere Internetseiten und sonstige Systeme durch technische und organisatorische Maßnahmen gegen Verlust, Zerstörung, Zugriff, Veränderung oder Verbreitung Ihrer Daten durch unbefugte Personen
                </p><br />

                <h5 className='font-weight-bold'>Betroffenenrechte</h5>
                <p>
                    Sie haben im Rahmen der geltenden gesetzlichen Bestimmungen jederzeit das Recht auf unentgeltliche Auskunft über Ihre bei uns gespeicherten personenbezogenen Daten, deren Herkunft und Empfänger und den Zweck der Datenverarbeitung und ggf. ein Recht auf Berichtigung, Sperrung oder Löschung dieser Daten. Hierzu sowie zu weiteren Fragen zum Thema personenbezogene Daten können Sie sich jederzeit unter den in Ziffer 1 genannten Kontaktdaten an uns wenden. Ihnen kann weiterhin ein Recht auf Einschränkung der Verarbeitung Ihrer Daten sowie ein Recht auf Herausgabe der von Ihnen bereitgestellten Daten in einem strukturierten, gängigen und maschinenlesbaren Format zustehen. Wenn Sie uns eine Einwilligung zur Verarbeitung personenbezogener Daten für bestimmte Zwecke erteilt haben, können Sie Ihre Einwilligung jederzeit mit Wirkung für die Zukunft widerrufen. Verarbeiten wir Ihre Daten zur Wahrung berechtigter Interessen, können Sie dieser Verarbeitung aus Gründen, die sich aus Ihrer besonderen Situation ergeben, widersprechen. Daneben haben Sie die Möglichkeit, sich an eine Datenschutzaufsichtsbehörde zu wenden (Beschwerderecht).
                </p><br />
            </div>
        )
    }
}

export default Privcy;