import http from "./httpService";
import config from "../config.json";

const apiEndpointRegister = config.apiUrl + "/register";
const apiEndpointComment = config.apiUrl + "/comments";

export function register(user) {
    return http.post(apiEndpointRegister, {
        name: user.name,
        email: user.email,
        password: user.password,
        password_confirmation: user.password_confirmation
    });
}

export function addComment(comment) {
    return http.post(apiEndpointComment, {
        name: comment.name,
        email: comment.email,
        phone: comment.phone,
        description: comment.description
    });
}
