import React, { Component } from "react";
import config from "../config.json";
import { toast } from "react-toastify";
import { FaTrashAlt } from 'react-icons/fa';
import http from "../services/httpService";

class CommentsTable extends Component {
    state = {
        comments: [],
        loading: true,
    }

    async componentDidMount() {
        const res = await http.get(config.apiUrl + "/comments")

        if (res.data.status === 200) {
            this.setState({
                comments: res.data.comments,
                loading: false,
            });
        }
    }

    handleDelete = async post => {
        const originalComments = this.state.comments;
        const comments = originalComments.filter(c => c.id !== post.id);
        this.setState({ comments });

        try {
            await http.delete(config.apiUrl + '/comments/' + post.id);
            // this.props.history.push("/admin");
            // window.location = "/admin";
        } catch (ex) {
            if (ex.response && ex.response.status === 404)
                toast.error("Dieser kommentar wurde bereits gelöscht.");

            this.setState({ comments: originalComments });
        }
    };


    render() {
        var comment_HTMLTABLE = "";
        if (this.state.loading) {
            comment_HTMLTABLE =
                <tr>
                    <td>
                        <button className="btn">
                            <span className="spinner-border spinner-border-sm"></span>
                            Loading..
                        </button>
                    </td>
                </tr>
        } else {
            comment_HTMLTABLE =
                this.state.comments.map((item) => {
                    return (
                        <tr key={item.id}>
                            <td>{item.name}</td>
                            <td>{item.email}</td>
                            <td>{item.phone}</td>
                            <td>{item.description}</td>
                            <td>
                                <button type="button" onClick={() => this.handleDelete(item)} className="btn btn-danger btn-sm">Löschen</button>
                            </td>
                        </tr>
                    )
                });
        }
        return (
            <table className="table table-striped">
                <thead>
                    <tr className="text-center font-weight-bold">
                        <td>Name</td>
                        <td>Email</td>
                        <td>Telefon</td>
                        <td>Beschreibung</td>
                        <td><FaTrashAlt /></td>
                    </tr>
                </thead>
                <tbody>
                    {comment_HTMLTABLE}
                </tbody>
            </table>
        );
    }
}

export default CommentsTable;