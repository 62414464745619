import React from 'react';
import { Link } from 'react-router-dom';
import { Fa500Px, FaInfo, FaBoxes } from 'react-icons/fa';

const Footer = () => {
    return (
        <footer style={{ with: "100%" }}>
            <div className="row bg-dark text-center pt-3 pb-3">
                <div className="col-xl-4 col-lg-4 col-xl-4 col-md-4 col-sm-12">
                    <Link className="text-white" to="/privcy"><Fa500Px className="mr-2" />DATENSCHUTZ</Link>
                </div>
                <div className="col-xl-4 col-lg-4 col-xl-4 col-md-4 col-sm-12">
                    <Link className="text-white" to="/impressum"><FaInfo className="mr-2" />IMPRESSUM</Link>
                </div>
                <div className="col-xl-4 col-lg-4 col-xl-4 col-md-4 col-sm-12">
                    <Link className="text-white" to="/membership"><FaBoxes className="mr-2" />Mitgliedschaften</Link>
                </div>
            </div>
            <div className="row bg-dark text-center pt-2 mb-3">
                <div className="col-xl-4 col-lg-4 col-xl-4 col-md-4 col-sm-12">
                </div>
                <div className="col-xl-4 col-lg-4 col-xl-4 col-md-4 col-sm-12">
                    <p className="text-muted text-center">© 2023 Copyright: montagekm1.de</p>
                </div>
                <div className="col-xl-4 col-lg-4 col-xl-4 col-md-4 col-sm-12">
                </div>
            </div>
        </footer>);
}

export default Footer;
