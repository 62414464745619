import React from 'react';
import { FaEnvelope, FaPhoneVolume, FaAddressCard } from 'react-icons/fa';
import config from "../config.json";
import Joi from 'joi-browser';
import Form from "./common/form";
import * as userService from '../services/userService';


class Contact extends Form {
    state = {
        data: { name: "", email: "", phone: "", description: "" },
        success: {},
        errors: {}
    };

    schema = {
        name: Joi.string()
            .required()
            .label("Name"),
        email: Joi.string()
            .required()
            .email()
            .label("Email"),
        phone: Joi.string()
            .required()
            .min(6)
            .label("Telefon"),
        description: Joi.string()
            .required()
            .label("Nachricht")
    };

    componentDidMount() {
        window.scrollTo(0, 0)
    }

    doSubmit = async () => {
        try {
            const response = await userService.addComment(this.state.data);
            console.log(response);
            if (response && response.status === 200) {
                const success = { ...this.state.success }
                success['successRegister'] = response.data.message;
                this.setState({ success });
                setTimeout(() => {
                    this.setState({
                        data: { name: "", email: "", phone: "", description: "" },
                        success: "",
                    });
                }, 3000);
            }
        } catch (ex) {
            if (ex.response && ex.response.status === 400) {
                const errors = { ...this.state.errors };
                errors.username = ex.response.data;
                this.setState({ errors });
            }
        }
    };

    render() {
        const { account, errors, success } = this.state;
        return (
            <div className="main mt-5 pt-5 m-3">
                <div className="row">
                    <div className='col-lg-6 col-xl-6 col-md-12 col-sm-12 text-muted pt-5 pl-1'>
                        <div className='text-light bg-dark p-3 pt-4'><h5 className="font-weight-bold">UNTERNEHMEN</h5></div>
                        <div className='border p-2'>
                            <p className="pl-2">
                                <i className='mr-2'><FaAddressCard /></i>
                                {config.address.land}<br />
                                {config.address.company}<br />
                                {config.address.street}<br />
                                {config.address.plz}
                                <span className="ml-1">
                                    {config.address.city}<br />
                                </span>

                            </p><hr />
                            <p className="pl-2">
                                <i className='mr-2'><FaPhoneVolume /></i>
                                {config.contact.phone}<br />
                                <i className='mr-2'>
                                    <FaEnvelope />
                                </i>
                                {config.contact.email}
                            </p>
                        </div>
                        <div className='border position-relative font-weight-bold p-2'>
                            <img

                                src={config.ImageUrl + "/uploads/products/contact/contact.webp"}
                                alt="contact mit uns"
                                width={"100%"}
                                height={"280px"}
                            />
                        </div>
                    </div>

                    <div className='col-lg-6 col-xl-6 col-md-12 col-sm-12 text-muted pt-5 pr-1'>
                        <div className='text-light bg-dark p-3 pt-4'><h5 className="font-weight-bold">SCHICKEN SIE UNS EINE NACHRICHT</h5></div>
                        <div>
                            {success.successRegister && <div className="text-white badge bg-success p-3 font-weight-bold m-3">{success.successRegister}</div>}
                            {errors.backend && <div className="text-white badge bg-danger p-3 m-3">{errors.backend}</div>}
                        </div>
                        <form id="commentForm" className='border p-5' onSubmit={this.handleSubmit}>
                            <div>
                                {this.renderInput("name", "Name:*")}
                            </div>
                            <div className="mt-4">
                                {this.renderInput("email", "Deine E-Mail-Adresse:*")}
                            </div>

                            <div className="mt-4">
                                {this.renderInput("phone", "Deine Telephon:*")}
                            </div>

                            <div className="mt-4">
                                {this.renderInput("description", "Nachricht:*")}
                            </div>

                            <div className="mt-4">
                                {this.renderButton("Senden")}
                            </div>
                        </form>
                    </div>
                </div>
                <div className='row mt-5'>
                    <div className='col-lg-12 col-xl-12 col-md-12 col-sm-12 text-muted pl-1 p-2 border m-1'>
                        <p>
                            <b>Allgemeine Anfrage:</b>
                            <br />Wir sind für Sie da! Haben Sie Fragen, Anregungen oder möchten Sie mehr über unsere Dienstleistungen erfahren? Zögern Sie nicht, uns zu kontaktieren. Wir stehen Ihnen gerne zur Verfügung, um Ihre Anliegen zu besprechen und Ihnen weiterzuhelfen.<br /><br />
                            <b>Terminvereinbarung:</b>
                            <br />Ihre Zeit ist kostbar, und wir möchten sicherstellen, dass wir für Sie da sind, wann immer Sie uns brauchen. Kontaktieren Sie uns, um einen persönlichen Beratungstermin zu vereinbaren. Wir sind flexibel und passen uns Ihrem Zeitplan an, um sicherzustellen, dass wir Ihre Bedürfnisse bestmöglich erfüllen.<br /><br />
                            <b>Individuelle Beratung:</b>
                            <br />Bei uns steht Ihre Individualität an erster Stelle. Unsere Experten sind bereit, Sie persönlich zu beraten und auf Ihre einzigartigen Wünsche einzugehen. Schreiben Sie uns oder rufen Sie an, um eine maßgeschneiderte Beratung zu erhalten, die auf Ihre Vorstellungen zugeschnitten ist<br />
                        </p>
                    </div>
                </div>
            </div>
        );
    }
}

export default Contact;