import React, { Component } from 'react';
import { ToastContainer } from 'react-toastify';
import { Route, Switch, Redirect } from 'react-router-dom';
import 'react-toastify/dist/ReactToastify.css';
import 'animate.css';
import './App.css';

import Navbar from './components/navbar';
import NotFound from './components/notFound';
import Project from './components/project';
import Abaut from './components/abaut';
import Contact from './components/contact';
import Career from './components/career';
import Home from './components/home';
import Privcy from './components/privcy';
import Impressum from './components/impressum';
import Membership from './components/membership';
import Admin from './components/admin';
//import RegisterForm from './components/registerForm';
import Login from './components/loginForm';
import Footer from './components/footer';
import Logout from './components/logout';
import auth from './services/authService';

class App extends React.Component {
  state = { name: "" };

  componentDidMount() {
    const name = auth.getCurrentUser();
    this.setState({ name });
  }
  render() {
    const { name } = this.state;
    return (
      <div>
        <ToastContainer />
        <Navbar user={this.state} />
        <div className="content" style={{ "minHeight": "1000px", "width": "100%" }}>
          <Switch>
            <Route path="/" exact component={Home} />
            <Route
              path="/project"
              render={props => <Project {...props} user={name} />} />
            <Route path="/abaut" component={Abaut} />
            <Route path="/contact" component={Contact} />
            <Route path="/career" component={Career} />
            <Route path="/notFound" component={NotFound} />

            <Route path="/privcy" component={Privcy} />
            <Route path="/impressum" component={Impressum} />
            <Route path="/membership" component={Membership} />

            <Route
              path="/admin"
              render={props => {
                if (!name) return <Redirect to="/login" />
                return <Admin {...props} />;
              }} />

            <Route
              path="/login"
              render={props => {
                if (name) return <Redirect to="/admin" />
                return <Login {...props} />;
              }}
            />
            {/* <Route path="/register" component={RegisterForm} /> */}
            <Route path="/logout" component={Logout} />

            <Redirect to="/notFound" />
          </Switch>
        </div>
        <Footer />
      </div>
    );
  }
}

export default App;
