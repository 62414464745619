import React from 'react';
import { Link, NavLink } from 'react-router-dom';
import config from "../config.json";
import { FaAlignJustify, FaFacebookSquare, FaInstagramSquare, FaLinkedin, FaXing, FaHome, FaCubes, FaUserTie, FaPhoneSquare, FaUserPlus } from 'react-icons/fa';

const Navbar = ({ user }) => {
    return (
        <nav className="navbar navbar-expand-md fixed-top navbar-dark bg-dark">
            <a className="navbar-brand font-weight-bold" href="/">
                <img className='d-inline' src={config.ImageUrl + "/uploads/products/logo.png"} width="50" height="50" alt="LOGO" />
                <h6 className='d-inline ml-2 mt-5'>KM1</h6>
            </a>

            <button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#collapsibleNavbar">
                <span><FaAlignJustify /></span>
            </button>

            <div className="collapse navbar-collapse" id="collapsibleNavbar">
                <ul className="navbar-nav">
                    <NavLink className="nav-item nav-link border-left" to="/">
                        <FaHome className="mr-1" />
                        <span>HOME</span>
                    </NavLink>
                    <NavLink className="nav-item nav-link" to="project">
                        <FaCubes className="mr-1" />
                        <span>PROJEKTE</span>
                    </NavLink>
                    <NavLink className="nav-item nav-link" to="/abaut">
                        <FaUserTie className="mr-1" />
                        <span>ÜBER UNS</span>
                    </NavLink>
                    <NavLink className="nav-item nav-link" to="/contact">
                        <FaPhoneSquare className="mr-1" />
                        <span>KONTAKT</span>
                    </NavLink>
                    <NavLink className="nav-item border-right nav-link" to="/career">
                        <FaUserPlus className="mr-1" />
                        <span>KARRIERE</span>
                    </NavLink>
                    <a className="nav-item nav-link mt-1" href={config.sozial.facebook} title="Facebook">
                        <FaFacebookSquare />
                    </a>
                    <a className="nav-item nav-link mt-1" href={config.sozial.instagram} title="Instagram">
                        <FaInstagramSquare />
                    </a>
                    <a className="nav-item nav-link mt-1" href={config.sozial.linkdin} title="Linkdin">
                        <FaLinkedin />
                    </a>
                    <a className="nav-item nav-link border-right mt-1" href={config.sozial.sink} title="Sink">
                        <FaXing />
                    </a>

                    {!user.name && (
                        <React.Fragment>
                            {/* <li className="nav-item border-left">
                                <Link className="nav-link" to="/register">REGISTER</Link>
                            </li> */}
                            <li className="nav-item">
                                <Link className="nav-link" to="/login">EINLOGEN</Link>
                            </li>
                        </React.Fragment>
                    )}
                    {user.name && (
                        <React.Fragment>
                            <li className="nav-item border-left">
                                <Link className="nav-link" to="/logout">AUSLOGGEN</Link>
                            </li>
                            <li className="nav-item">
                                <Link className="nav-link" to="/admin">{user.name}</Link>
                            </li>
                        </React.Fragment>
                    )}
                </ul>
            </div>
        </nav>
    );
}

export default Navbar;

