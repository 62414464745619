import React, { Component } from "react";
import ProjectRow from "./common/projectRow";
import { toast } from "react-toastify";
import config from "../config.json";
import axios from "axios";

export default class Images extends Component {
    constructor(props) {
        super(props);

        this.state = {
            images: [],
        };
    }

    componentDidMount() {
        window.scrollTo(0, 0)
        this.getImages();
    }

    getImages = () => {
        axios.get(config.apiUrl + "/products")
            .then((response) => {
                if (response.status === 200) {
                    this.setState({
                        images: response.data.data,
                    });
                }
            })
            .catch((error) => {
                console.error(error);
            });
    };

    handleDelete = async ProjectId => {
        const originalImages = this.state.images;
        const images = originalImages.filter(i => i.id !== ProjectId);
        this.setState({ images });

        try {
            await axios.delete(config.apiUrl + '/products/' + ProjectId);
            // this.props.history.push("/project");
            // window.location = "/project";
        } catch (ex) {
            if (ex.response && ex.response.status === 404)
                toast.error("Dieser Projekt wurde bereits gelöscht. ");

            this.setState({ images: originalImages });
        }
    };

    render() {
        const { user } = this.props;
        return (
            <div >
                {
                    this.state.images.length > 0 ? (
                        this.state.images.map((item) => (
                            <ProjectRow key={item.id} itemRow={item} user={user} onDelete={this.handleDelete} />
                        ))
                    ) : (
                        <button className="btn btn-dark" style={{marginTop:"100px",marginLeft:"20px"}}>
                            <span className="spinner-border spinner-border-sm"></span>
                            Projekte werden geladen..
                        </button>
                    )
                }
            </div>
        );
    }
}



//https://programmingfields.com/file-upload-in-react-js-using-laravel-8-restful-api/


































































// import React, { Component } from 'react';

// import Project1 from '../components/img/project1.jpg';
// import Project2 from '../components/img/project2.jpg';
// import Project3 from '../components/img/project3.jpg';
// import Project4 from '../components/img/project4.jpg';
// import Project5 from '../components/img/project5.jpg';
// import Project6 from '../components/img/project6.jpg';

// class Project extends React.Component {
//     render() {
//         return (
//             <div className='mt-5'>
//                 <div className='row p-2 text-primary'>
//                     <div className='col-lg-4'>
//                         <div className='shrink position-relative'>
//                             <p className='position-absolute font-weight-bold ml-5 pt-2'>Erste Projekt mit balue Licht:</p>
//                             <img src={Project1} className="img-thumbnail" alt="PROJECT" style={{ "height": "300px", "width": "100%" }} />
//                         </div>
//                     </div>
//                     <div className='col-lg-4'>
//                         <div className='shrink position-relative'>
//                             <p className='position-absolute font-weight-bold ml-5 pt-2'>Yweite Projekt mit gjkgde Licht:</p>
//                             <img src={Project2} className="img-thumbnail" alt="PROJECT" style={{ "height": "300px", "width": "100%" }} />
//                         </div>
//                     </div>
//                     <div className='col-lg-4'>
//                         <div className='shrink position-relative'>
//                             <p className='position-absolute font-weight-bold ml-5 pt-2'>Driete Projekt mit dged Licht:</p>
//                             <img src={Project3} className="img-thumbnail" alt="PROJECT" style={{ "height": "300px", "width": "100%" }} />
//                         </div>
//                     </div>
//                 </div>
//                 <div className='row p-2 text-primary'>
//                     <div className='col-lg-4 position-relative'>
//                         <div className='shrink position-relative'>
//                             <p className=' position-absolute font-weight-bold ml-5 pt-2'>Fierte Projekt mit ergt fgtd:</p>
//                             <img src={Project4} className="img-thumbnail" alt="PROJECT" style={{ "height": "300px", "width": "100%" }} />
//                         </div>
//                     </div>
//                     <div className='col-lg-4'>
//                         <div className='shrink position-relative'>
//                             <p className=' position-absolute font-weight-bold ml-5 pt-2'>Funfte Projekt mit rghrth dfge:</p>
//                             <img src={Project5} className="img-thumbnail" alt="PROJECT" style={{ "height": "300px", "width": "100%" }} />
//                         </div>
//                     </div>
//                     <div className='col-lg-4'>
//                         <div className='shrink position-relative'>
//                             <p className='position-absolute font-weight-bold ml-5 pt-2'>Letzte Projekt mit gjmn Licht:</p>
//                             <img src={Project6} className="img-thumbnail" alt="PROJECT" style={{ "height": "300px", "width": "100%" }} />
//                         </div>
//                     </div>
//                 </div>
//             </div>
//         );
//     }
// }

// export default Project;