import React, { Component } from 'react';

class Memberschip extends React.Component {

    componentDidMount() {
        window.scrollTo(0, 0)
    }

    render() {
        return (
            <div className='p-5 m-5 text-dark'>
                <p className='font-weight-bold'>
                    <b>Mitgliedschaften</b>
                </p>
                <hr />
                <p>
                    Die Bundesstiftung Baukultur möchte auf breiter fachlicher, gesellschaftlicher und politischer Ebene zu Qualitätsdebatten über Baukultur anregen und die Öffentlichkeit für das Thema sensibilisieren. Auch auf internationaler Ebene wirbt sie für die Qualität deutscher Baukultur.
                </p><br />
            </div>
        );
    }
}

export default Memberschip;