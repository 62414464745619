import React from 'react';
import config from "../config.json";

class Impressum extends React.Component {

    componentDidMount() {
        window.scrollTo(0, 0)
    }

    render() {
        return (
            <div className='p-5 m-5 text-dark'>
                <h5 className='font-weight-bold'>IMPRESSUM</h5>
                <hr />
                <p>
                    Diensteanbieter gemäß § 5 Telemediengesetz (TMG):<br />
                    {config.address.company}<br />
                    {config.address.street}<br />
                    {config.address.plz} {config.address.city}<br /><br />

                    {config.contact.phone}<br />
                    {config.contact.email}<br />
                    www.montagekm1.de<br /><br />
                    Geschäftsführer und verantwortlich<br />
                    Ing Mehran Abbasi Moghaddam
                    <br />
                    <br />
                    Die Berufsbezeichnungen wurden in der Bundesrepublik Deutschland verliehen und die Geschäftsführer sind Mitglieder der Architektenkammer Dortmund.
                    <br />
                    <br />
                    Zuständige Aufsichtsbehörde ist die Architektenkammer {config.address.city}, {config.address.street} {config.address.plz} {config.address.city}.
                    <br />
                    <br />
                    Die maßgeblichen berufsrechtlichen Regelungen sind das Baukammergesetz des Landes Dortmund und die Durchführungsverordnung zum Baukammergesetz sowie die Satzung der Architektenkammer Dortmund. Zugänglich sind die genannten berufsrechtlichen Regelungen über die Internetseite der Architektenkammer Dortmund in der Rubrik „Mitglieder/Rechte und Gesetze/Gesetze und Verordnungen“.
                    <br />
                    <br />
                    Die Inhalte und Gestaltung unserer Internetseiten sind urheberrechtlich geschützt. Die Veröffentlichung, Übernahme oder Nutzung von Texten, Bildern oder anderen Daten der Webseite bedarf der vorherigen schriftlichen Zustimmung der Talebi Web-Developer.
                    <br />
                    <br />
                    Trotz sorgfältiger inhaltlicher Kontrolle übernehmen wir keine Haftung für die Inhalte externer Links. Für den Inhalt der verlinkten Seite sind ausschließlich deren Betreiber verantwortlich.
                    <br />
                    <br />
                    Inhaltlich Verantwortliche (gemäß § 55 Abs. 2 RStV):
                    Mehran Abbasi Moghadam (Anschrift wie oben)
                    <br />
                    <br />
                    Konzept/Design: Talebi
                    Digital-Agentur: Talebi
                    Fotografen: MONTAGEKM1,
                    Oliver Tjaden, Patrick Tiedtke, Nick Wolff
                    Wir bedanken uns für die Fotos, die uns unsere Kunden zur Verfügung gestellt haben.
                </p><br />
            </div>
        );
    }
}

export default Impressum;