import React, { Component } from 'react';
import config from "../config.json";
import CommentsTable from "./commentsTable";
import axios from 'axios';
import { FcInfo } from "react-icons/fc";


class Admin extends Component {
    constructor(props) {
        super(props);

        this.state = {
            text: {
                name: "",
                description: ""
            },
            images: {
                image1: "",
                image2: "",
                image3: "",
                image4: "",
            },
            responseMsg: {
                status: "",
                message: "",
                error: "",
            },
        };
    }

    handleChange = ({ currentTarget: input }) => {
        const text = { ...this.state.text };
        text[input.name] = input.value;
        this.setState({ text });
    };


    // image onchange hander
    handleChangeFile = (e) => {
        const images = { ...this.state.images };
        images[e.target.name] = e.target.files[0];
        this.setState({ images })
    }

    // submit handler
    submitHandler = (e) => {
        e.preventDefault();
        const data = new FormData();
        data.append('name', this.state.text.name);
        data.append('description', this.state.text.description);
        data.append('image1', this.state.images.image1);
        data.append('image2', this.state.images.image2);
        data.append('image3', this.state.images.image3);
        data.append('image4', this.state.images.image4);

        axios.post(config.apiUrl + "/products", data)
            .then((response) => {
                if (response.status === 200) {
                    this.setState({
                        responseMsg: {
                            status: response.data.status,
                            message: response.data.message,
                        },
                    });
                    setTimeout(() => {
                        this.setState({
                            image: "",
                            responseMsg: "",
                        });
                    }, 2000);

                    document.querySelector("#imageForm").reset();
                }
            })
            .catch((error) => {
                console.error(error);
            });
    }

    render() {
        const { comments } = this.state;
        return (
            <div className="p-5 mt-5">
                <div className="row">
                    <div className="col-xl-8 col-lg-8 col-md-8 col-sm-12">
                        <div className='card shadow overflow-auto' style={{ "height": "500px", "width": "100%" }}>
                            <div className="card-header">
                                <h5 className="card-title fw-bold">
                                    NACHRICHTEN
                                </h5>
                            </div>
                            <div className="card-body">
                                <CommentsTable onDelete={this.handleDelete} />
                            </div>
                        </div>
                    </div>
                    <div className="col-xl-4 col-lg-4 col-md-4 col-sm-12 d-flex justify-content-center">
                        <form onSubmit={this.submitHandler} encType="multipart/form-data" id="imageForm">
                            <div className="card shadow">
                                {this.state.responseMsg.status === "successs" ? (
                                    <div className="alert alert-success">
                                        {this.state.responseMsg.message}
                                    </div>
                                ) : this.state.responseMsg.status === "failed" ? (
                                    <div className="alert alert-danger">
                                        {this.state.responseMsg.message}
                                    </div>
                                ) : (
                                    ""
                                )}
                                <div className="card-header">
                                    <h5 className="card-title fw-bold">
                                        PROJEKT HOCHLADEN
                                    </h5>
                                </div>

                                <div className="card-body">
                                    <div className="form-group py-2">
                                        <FcInfo /><label className='ml-2' htmlFor="name">Bezeichnung des Projekt :</label>
                                        <input
                                            type="text"
                                            name="name"
                                            onChange={this.handleChange}
                                            className="form-control"
                                        />
                                        <span className="text-danger">
                                            {this.state.responseMsg.error}
                                        </span>
                                    </div>

                                    <div className="form-group py-2">
                                        <FcInfo /><label className='ml-2' htmlFor="description">Text des Projekt :</label>
                                        <textarea
                                            className="form-control"
                                            id="description"
                                            name="description"
                                            onChange={this.handleChange}
                                            rows="3">
                                        </textarea>
                                        <span className="text-danger">
                                            {this.state.responseMsg.error}
                                        </span>
                                    </div>

                                    <div className="form-group py-2">
                                        <FcInfo /><label className='ml-2' htmlFor="image1">Bild 1:</label>
                                        <input
                                            type="file"
                                            name="image1"
                                            onChange={this.handleChangeFile}
                                            className="form-control"
                                        />
                                        <span className="text-danger">
                                            {this.state.responseMsg.error}
                                        </span>
                                    </div>

                                    <div className="form-group py-2">
                                        <FcInfo /><label className='ml-2' htmlFor="image2">Bild 2:</label>
                                        <input
                                            type="file"
                                            name="image2"
                                            onChange={this.handleChangeFile}
                                            className="form-control"
                                        />
                                        <span className="text-danger">
                                            {this.state.responseMsg.error}
                                        </span>
                                    </div>

                                    <div className="form-group py-2">
                                        <FcInfo /><label className='ml-2' htmlFor="image3">Bild 3:</label>
                                        <input
                                            type="file"
                                            name="image3"
                                            onChange={this.handleChangeFile}
                                            className="form-control"
                                        />
                                        <span className="text-danger">
                                            {this.state.responseMsg.error}
                                        </span>
                                    </div>

                                    <div className="form-group py-2">
                                        <FcInfo /><label className='ml-2' htmlFor="image4">Bild 4:</label>
                                        <input
                                            type="file"
                                            name="image4"
                                            onChange={this.handleChangeFile}
                                            className="form-control"
                                        />
                                        <span className="text-danger">
                                            {this.state.responseMsg.error}
                                        </span>
                                    </div>

                                </div>

                                <div className="card-footer">
                                    <button type="submit" className="btn btn-dark">
                                        Hochladen
                                    </button>
                                </div>
                            </div>
                        </form>
                    </div>

                </div>


                <div className="row">
                    <div className="col-xl-4 col-lg-4 col-md-6 col-sm-12 m-auto">

                    </div>
                </div>
            </div>
        );
    }

}

export default Admin;